import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../icon';
import { useOutClickHook } from '../../../../utils/hooks';
import { accessibleOnClick } from '../../../../utils/accessible-on-click';
import Button from '../button';
import * as Icons from '../../../../../shared/svg';
import { OverlayTooltip, Placement } from '../../overlay';

const ExportButton = ({
  handleExport,
  handleConsolidatedExport,
  isDropdownFlow = false,
  iconOnly = false,
}: {
  handleExport: () => void;
  handleConsolidatedExport?: () => void;
  isDropdownFlow?: boolean;
  iconOnly?: boolean;
}) => {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const closeDropdown = () => setShowDropdown(false);
  const toggleDropdown = () => setShowDropdown(!showDropdown);
  const dropdownRef = useRef(null);
  useOutClickHook(dropdownRef, closeDropdown);

  if (!isDropdownFlow) {
    if (iconOnly) {
      return (
        <Button
          className="d-flex justify-content-center action-btn--export icon-only"
          onClick={handleExport}
          theme={Button.Theme.Ghost}
          iconLeft={<Icon className="font-20" identifier="download" />}
        />
      );
    }

    return (
      <Button
        className="d-flex justify-content-center action-btn--export"
        onClick={handleExport}
        theme={Button.Theme.Ghost}
        iconLeft={<Icon className="font-20" identifier="download" />}
      >
        {iconOnly ? '' : t('labels.export')}
      </Button>
    );
  }

  return (
    <>
      <div
        ref={dropdownRef}
        className="d-flex align-items-center pointer"
        {...accessibleOnClick(toggleDropdown)}
      >
        <Icons.Download
          height={20}
          width={20}
          className={`mr-2 font-20  ${
            showDropdown ? 'blue-txt-11' : 'gray-txt-15'
          }`}
        />
        <span
          className={`export-dropdown-span ${
            showDropdown ? 'blue-txt-11' : 'gray-txt-15'
          }`}
        >
          {t('labels.export')}
        </span>
        {showDropdown && (
          <div className="export-dropdown-view">
            <ul className="list-unstyled profile-dropdown-hovered-list">
              <li className="my-1 menu-item">
                <OverlayTooltip
                  text="Download a detailed report of all emails sent, including metrics for opens, clicks, and replies for each Prospect"
                  placement={Placement.LeftStart}
                >
                  <div
                    className="d-flex align-items-center px-2 py-2 export-cursor-pointer"
                    {...accessibleOnClick(handleConsolidatedExport)}
                  >
                    <div className="text-left">
                      <p className="export-list-font menu-text">
                        Detailed Report
                      </p>
                    </div>
                  </div>
                </OverlayTooltip>
              </li>
              <li className="my-1 menu-item export-feature-icon-color">
                <OverlayTooltip
                  text="Export the current table view with a breakdown of the data displayed."
                  placement={Placement.LeftStart}
                >
                  <div
                    className="d-flex align-items-center px-2 py-2 export-cursor-pointer"
                    {...accessibleOnClick(handleExport)}
                  >
                    <div className="text-left">
                      <p className="export-list-font menu-text">
                        Table Breakdown
                      </p>
                    </div>
                  </div>
                </OverlayTooltip>
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default ExportButton;
