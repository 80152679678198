import { createSlice } from '@reduxjs/toolkit';
import { constants } from '../../shared/enums/constants';
import { RequestStatus } from '../../shared/enums/request-status';
import { ResponseErrorWithHandled } from '../../shared/types';
import { AddProspectResponse } from '../sequence/types';
import { Field } from '../settings/types/field';
import {
  getProspectRequest,
  getProspectCountRequest,
  getSequenceListRequest,
  getStepListRequest,
  addToStepRequest,
  removeProspectRequest,
  unsubscribeProspectRequest,
  getSingleProspectDetailsRequest,
  getSingleProspectSequencesDetailsRequest,
  singleProspectSequencesMarkAsRepliedRequest,
  singleProspectRemoveFromSequencesRequest,
  saveContactAttributesRequest,
  saveContactDealValueRequest,
  getFieldsRequest,
  addProspectManuallyRequest,
  singleProspectSequencesMarkAsFinishedRequest,
  verifyProspectsManuallyRequest,
  getTagsRequest,
  addTagsToProspectsRequest,
  removeTagsFromProspectsRequest,
  verifyBulkProspectsRequest,
  deleteBulkProspectsRequest,
  unsubscribeBulkProspectRequest,
  addBulkProspectsToStepRequest,
  addTagsToBulkProspectsRequest,
  removeTagsFromBulkProspectsRequest,
  changePrimaryEmailInProspectRequest,
  deleteSecondaryEmailRequest,
  deleteSecondaryPhoneRequest,
  getProspectListRequest,
  getProspectListCountRequest,
  getProspectListByIdsRequest,
  getProspectFiltersFieldsListRequest,
} from './extra-actions';
import {
  getProspectsFiltersFromLocalStore,
  removeProspectsFiltersFromLocalStore,
  setProspectsFiltersInLocalStore,
} from './helpers/prospect-filters-resolver';
import { Filters, ProspectPagination, SelectAllContacts } from './types';
import {
  ProspectsFilters,
  ProspectFilterResetArg,
} from '../../shared/types/prospects-filters';
import { getMergedProspects } from './helpers/utils/get-merged-prospects';
import { ProspectSortBy } from './enums/prospect';
import { SortOrder } from '../../shared/enums/order';
import { ProspectFilterField } from './components/prospect-filters-modal/types';
import { getProspectTableColumnsFromLocalStorage } from './components/prospect-table/utils/helper';

interface RequestState {
  status: RequestStatus;
  message: string;
  error: ResponseErrorWithHandled;
}

export const prospectsFiltersInitialState: ProspectsFilters = {
  tags: [],
  owners: [],
  status: [],
  outcomes: [],
  sequences: [],
  'verification status': [],
  'task priority': [],
  'created date': [],
  'creation source': [],
  'email account': [],
  'client associated': [],
};

interface State {
  getProspectRequest: RequestState;
  getSequenceListRequest: RequestState;
  getStepListRequest: RequestState;
  getProspectCountRequest: RequestState;
  addToStepRequest: RequestState;
  addToStepRequestResultCount: {
    failed: number;
    successful: number;
    resultData: any;
  };
  removeProspectRequest: RequestState;
  unsubscribeProspectRequest: RequestState;
  getSingleProspectDetailsRequest: RequestState;
  getSingleProspectSequencesDetailsRequest: RequestState;
  singleProspectSequencesMarkAsRepliedRequest: RequestState;
  singleProspectSequencesMarkAsFinishedRequest: RequestState;
  singleProspectRemoveFromSequencesRequest: RequestState;
  saveContactAttributesRequest: RequestState;
  saveContactDealValueRequest: RequestState;
  getFieldsRequest: RequestState;
  addProspectManuallyRequest: RequestState;
  verifyProspectManuallyRequest: RequestState;
  getTagsRequest: RequestState;
  addTagsToProspectsRequest: RequestState;
  removeTagsFromProspectsRequest: RequestState;
  verifyBulkProspectsRequest: RequestState;
  deleteBulkProspectsRequest: RequestState;
  unsubscribeBulkProspectRequest: RequestState;
  addBulkProspectsToStepRequest: RequestState;
  addTagsToBulkProspectsRequest: RequestState;
  removeTagsFromBulkProspectsRequest: RequestState;
  changePrimaryEmailInProspectRequest: RequestState;
  deleteSecondaryEmailRequest: RequestState;
  deleteSecondaryPhoneRequest: RequestState;
  search: string;
  steps: any[];
  sequences: any[];
  selectedContacts: any[];
  selectAllContactDetails: SelectAllContacts;
  singleProspect: any;
  singleProspectSequenceDetails: any[];
  tags: any[];
  isProspectSubscribed: boolean;
  prospectPaginationData: ProspectPagination;
  addProspectResponse: AddProspectResponse;
  prospectsFilters: ProspectsFilters;

  // New State
  prospects: any[];
  prospectCount: number;
  filters: Filters;
  fields: Field[];
  prospectFiltersFieldsList: ProspectFilterField[];

  getProspectListRequest: RequestState;
  getProspectListCountRequest: RequestState;
  getProspectListByIdsRequest: RequestState;
  getProspectFiltersFieldsListRequest: RequestState;
}

const initialState: State = {
  getProspectListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getProspectListCountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getProspectListByIdsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getProspectFiltersFieldsListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },

  getProspectRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSequenceListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getStepListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getProspectCountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSingleProspectDetailsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSingleProspectSequencesDetailsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  addToStepRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  singleProspectSequencesMarkAsRepliedRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  singleProspectSequencesMarkAsFinishedRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  singleProspectRemoveFromSequencesRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  unsubscribeProspectRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  removeProspectRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  saveContactAttributesRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  saveContactDealValueRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getFieldsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  addProspectManuallyRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },

  verifyProspectManuallyRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getTagsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  addTagsToProspectsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  removeTagsFromProspectsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  verifyBulkProspectsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteBulkProspectsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  unsubscribeBulkProspectRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  addBulkProspectsToStepRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  addTagsToBulkProspectsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  removeTagsFromBulkProspectsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  changePrimaryEmailInProspectRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteSecondaryEmailRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteSecondaryPhoneRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  prospectPaginationData: {
    pageNum: constants.DEFAULT_PAGE_NUM,
    pageSize: constants.PROSPECT_PAGE_SIZE,
  },
  search: null,
  sequences: [],
  steps: [],
  selectedContacts: [],
  selectAllContactDetails: null,
  singleProspect: null,
  singleProspectSequenceDetails: [],
  tags: [],
  isProspectSubscribed: null,
  addToStepRequestResultCount: {
    failed: 0,
    successful: 0,
    resultData: null,
  },
  addProspectResponse: null,
  prospectsFilters:
    getProspectsFiltersFromLocalStore() || prospectsFiltersInitialState,

  // New State
  prospects: [],
  prospectCount: 0,
  filters: {
    pageNum: 1,
    pageSize: 25,
    sortBy: ProspectSortBy.CreateAt,
    sortOrder: SortOrder.DESC,
    search: '',
    selectedFields: getProspectTableColumnsFromLocalStorage() || [],
    shouldFetchProspectList: false,
    shouldFetchProspectListCount: false,
  },
  fields: [],
  prospectFiltersFieldsList: [],
};

const prospectSlice = createSlice({
  name: 'prospect',
  initialState,
  reducers: {
    resetGetProspectListRequest: (state) => {
      state.getProspectListRequest = initialState.getProspectListRequest;
    },
    resetGetProspectListCountRequest: (state) => {
      state.getProspectListCountRequest =
        initialState.getProspectListCountRequest;
    },
    resetGetProspectListByIdsRequest: (state) => {
      state.getProspectListByIdsRequest =
        initialState.getProspectListByIdsRequest;
    },
    resetGetProspectFiltersFieldsListRequest: (state) => {
      state.getProspectFiltersFieldsListRequest =
        initialState.getProspectFiltersFieldsListRequest;
    },

    setFilters: (
      state,
      action: {
        payload: Partial<Filters>;
      },
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },

    resetProspect: (state) => {
      state.prospectCount = initialState.prospectCount;
      state.prospectPaginationData = initialState.prospectPaginationData;
      state.prospects = initialState.prospects;
      state.search = initialState.search;
      state.getProspectRequest = initialState.getProspectRequest;
    },

    resetRemoveProspectRequest: (state) => {
      state.removeProspectRequest.status = RequestStatus.Ideal;
      state.removeProspectRequest.error = null;
      state.removeProspectRequest.message = null;
    },

    resetAddToProspectForm: (state) => {
      state.addProspectManuallyRequest =
        initialState.addProspectManuallyRequest;
      state.addProspectResponse = initialState.addProspectResponse;
    },

    resetSequenceAndStepData: (state) => {
      state.sequences = initialState.sequences;
      state.steps = initialState.steps;
      state.getStepListRequest = initialState.getStepListRequest;
      state.getSequenceListRequest = initialState.getSequenceListRequest;
      state.addToStepRequest = initialState.addToStepRequest;
      state.removeProspectRequest = initialState.removeProspectRequest;
      state.singleProspectRemoveFromSequencesRequest =
        initialState.singleProspectRemoveFromSequencesRequest;
      state.singleProspectSequencesMarkAsRepliedRequest =
        initialState.singleProspectSequencesMarkAsRepliedRequest;
      state.singleProspectSequencesMarkAsFinishedRequest =
        initialState.singleProspectSequencesMarkAsFinishedRequest;
      state.unsubscribeProspectRequest =
        initialState.unsubscribeProspectRequest;
      state.saveContactAttributesRequest =
        initialState.saveContactAttributesRequest;
      state.saveContactDealValueRequest =
        initialState.saveContactDealValueRequest;
      state.verifyProspectManuallyRequest =
        initialState.verifyProspectManuallyRequest;
    },
    resetStep: (state) => {
      state.steps = initialState.steps;
      state.addToStepRequest = initialState.addToStepRequest;
    },
    selectAllContacts: (state, action) => {
      state.selectAllContactDetails = action.payload;
    },
    clearSelection: (state) => {
      state.selectAllContactDetails = initialState.selectAllContactDetails;
      state.selectedContacts = initialState.selectedContacts;
    },
    resetSaveContactAttributesRequestStatus: (state) => {
      state.saveContactAttributesRequest =
        initialState.saveContactAttributesRequest;
    },
    resetProspectDeleteEmailRequestStatus: (state) => {
      state.deleteSecondaryEmailRequest =
        initialState.deleteSecondaryEmailRequest;
    },
    resetProspectDeletePhoneRequestStatus: (state) => {
      state.deleteSecondaryPhoneRequest =
        initialState.deleteSecondaryPhoneRequest;
    },
    resetProspectPrimaryEmailChangeRequestStatus: (state) => {
      state.changePrimaryEmailInProspectRequest =
        initialState.changePrimaryEmailInProspectRequest;
    },
    checkAll: (state, action) => {
      if (action.payload.status === true) {
        if (state.selectAllContactDetails?.isAllProspectsSelected) {
          action.payload.rows.forEach(({ id: contactId }) => {
            state.selectAllContactDetails.deSelectedContactIds = state.selectAllContactDetails.deSelectedContactIds.filter(
              (id) => contactId !== id,
            );
          });
        }
        state.selectedContacts = state.selectedContacts.concat(
          action.payload.rows,
        );
      } else {
        if (state.selectAllContactDetails?.isAllProspectsSelected) {
          action.payload.rows.forEach(({ id }) => {
            state.selectAllContactDetails.deSelectedContactIds = state.selectAllContactDetails?.deSelectedContactIds.concat(
              [id],
            );
          });
        }
        state.selectedContacts = state.selectedContacts.reduce((acc, item) => {
          let isPresent = false;
          action.payload.rows.forEach(({ id }) => {
            if (id === item.id) {
              isPresent = true;
            }
          });
          if (isPresent) {
            return acc;
          }
          return [...acc, item];
        }, []);
      }
    },
    setProspectDetails: (state, action) => {
      const prospectWithId = state.prospects.find(
        (prospect) => prospect.id === action.payload,
      );

      // const { user } = prospectWithId;

      // const listOfSequences = prospectWithId.sequenceProspects.map(
      //   (sequence) => {
      //     sequence.currentStep.sequence.user = user;
      //     return sequence;
      //   },
      // );

      const attributes = {
        id: prospectWithId.id,
        attributes: prospectWithId.attributes,
        tags: prospectWithId.tags,
        isBlacklisted: prospectWithId.isBlacklisted,
      };

      // state.singleProspectSequenceDetails = listOfSequences;
      state.singleProspect = attributes;
      state.isProspectSubscribed = prospectWithId.isSubscribed;
    },
    resetSelectedContacts: (state) => {
      state.selectedContacts = initialState.selectedContacts;
    },
    checkSingle: (state, action) => {
      if (action.payload.status === true) {
        if (state.selectAllContactDetails?.isAllProspectsSelected) {
          state.selectAllContactDetails.deSelectedContactIds = state.selectAllContactDetails.deSelectedContactIds.filter(
            (id) => id !== action.payload.row.id,
          );
        }
        state.selectedContacts = state.selectedContacts.concat([
          action.payload.row,
        ]);
      } else {
        if (state.selectAllContactDetails?.isAllProspectsSelected) {
          state.selectAllContactDetails.deSelectedContactIds = state.selectAllContactDetails?.deSelectedContactIds.concat(
            [action.payload.row.id],
          );
        }
        state.selectedContacts = state.selectedContacts.filter(
          ({ id }) => id !== action.payload.row.id,
        );
      }
    },
    changeProspectPagination: (state, action) => {
      state.prospectPaginationData = action.payload;
    },

    saveSearch: (state, action) => {
      state.search = action.payload;
    },
    setProspectsFilters: (state, action) => {
      state.prospectsFilters = { ...action.payload };
      setProspectsFiltersInLocalStore(action.payload);
    },
    resetProspectsFilters: (state, action) => {
      const resetOff: ProspectFilterResetArg = action.payload;
      if (resetOff === 'all') {
        state.prospectsFilters = prospectsFiltersInitialState;

        removeProspectsFiltersFromLocalStore();
      } else {
        state.prospectsFilters[resetOff] = [];
        setProspectsFiltersInLocalStore(state.prospectsFilters);
      }
    },
    resetTags: (state) => {
      state.addTagsToProspectsRequest = initialState.addTagsToProspectsRequest;
      state.removeTagsFromProspectsRequest =
        initialState.removeTagsFromProspectsRequest;
    },
    resetBulkRequests: (state) => {
      state.verifyBulkProspectsRequest =
        initialState.verifyBulkProspectsRequest;
      state.deleteBulkProspectsRequest =
        initialState.deleteBulkProspectsRequest;
      state.unsubscribeBulkProspectRequest =
        initialState.unsubscribeBulkProspectRequest;
      state.addBulkProspectsToStepRequest =
        initialState.addBulkProspectsToStepRequest;
      state.addTagsToBulkProspectsRequest =
        initialState.addTagsToBulkProspectsRequest;
      state.removeTagsFromBulkProspectsRequest =
        initialState.removeTagsFromBulkProspectsRequest;
    },
    resetUnsubscribeRequestState: (state) => {
      state.unsubscribeProspectRequest =
        initialState.unsubscribeProspectRequest;
    },
  },
  extraReducers: (builder) => {
    // Get Prospect List
    builder.addCase(getProspectListRequest.pending, (state) => {
      state.getProspectListRequest.status = RequestStatus.Pending;
      state.getProspectListRequest.error = null;
    });
    builder.addCase(getProspectListRequest.fulfilled, (state, action) => {
      state.getProspectListRequest.status = RequestStatus.Succeeded;
      state.getProspectListRequest.message = action.payload.message;
      state.prospects = action.payload.payload;
    });
    builder.addCase(getProspectListRequest.rejected, (state, action) => {
      state.getProspectListRequest.status = RequestStatus.Failed;
      state.getProspectListRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Prospect List Count
    builder.addCase(getProspectListCountRequest.pending, (state) => {
      state.getProspectListCountRequest.status = RequestStatus.Pending;
      state.getProspectListCountRequest.error = null;
    });
    builder.addCase(getProspectListCountRequest.fulfilled, (state, action) => {
      state.getProspectListCountRequest.status = RequestStatus.Succeeded;
      state.getProspectListCountRequest.message = action.payload.message;
      state.prospectCount = action.payload.payload;
    });
    builder.addCase(getProspectListCountRequest.rejected, (state, action) => {
      state.getProspectListCountRequest.status = RequestStatus.Failed;
      state.getProspectListCountRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Prospect List By Ids
    builder.addCase(getProspectListByIdsRequest.pending, (state) => {
      state.getProspectListByIdsRequest.status = RequestStatus.Pending;
      state.getProspectListByIdsRequest.error = null;
    });
    builder.addCase(getProspectListByIdsRequest.fulfilled, (state, action) => {
      state.getProspectListByIdsRequest.status = RequestStatus.Succeeded;
      state.getProspectListByIdsRequest.message = action.payload.message;
      state.prospects = getMergedProspects(
        state.prospects,
        action.payload.payload,
      );
    });
    builder.addCase(getProspectListByIdsRequest.rejected, (state, action) => {
      state.getProspectListByIdsRequest.status = RequestStatus.Failed;
      state.getProspectListByIdsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Prospect Filter Fields List
    builder.addCase(getProspectFiltersFieldsListRequest.pending, (state) => {
      state.getProspectFiltersFieldsListRequest.status = RequestStatus.Pending;
      state.getProspectFiltersFieldsListRequest.error = null;
    });
    builder.addCase(
      getProspectFiltersFieldsListRequest.fulfilled,
      (state, action) => {
        state.getProspectFiltersFieldsListRequest.status =
          RequestStatus.Succeeded;
        state.getProspectFiltersFieldsListRequest.message =
          action.payload.message;
        state.prospectFiltersFieldsList = action.payload.payload.fields;
      },
    );
    builder.addCase(
      getProspectFiltersFieldsListRequest.rejected,
      (state, action) => {
        state.getProspectFiltersFieldsListRequest.status = RequestStatus.Failed;
        state.getProspectFiltersFieldsListRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Prospect Request
    builder.addCase(getProspectRequest.pending, (state) => {
      state.getProspectRequest.status = RequestStatus.Pending;
      state.getProspectRequest.error = null;
    });
    builder.addCase(getProspectRequest.fulfilled, (state, action) => {
      state.getProspectRequest.status = RequestStatus.Succeeded;
      state.getProspectRequest.message = action.payload.message;
      state.prospects = action.payload.payload.contacts;
    });
    builder.addCase(getProspectRequest.rejected, (state, action) => {
      state.getProspectRequest.status = RequestStatus.Failed;
      state.getProspectRequest.error =
        !action.payload.isHandled && action.payload;
    });
    // Prospect Count Request
    builder.addCase(getProspectCountRequest.pending, (state) => {
      state.getProspectCountRequest.status = RequestStatus.Pending;
      state.getProspectCountRequest.error = null;
    });
    builder.addCase(getProspectCountRequest.fulfilled, (state, action) => {
      state.getProspectCountRequest.status = RequestStatus.Succeeded;
      state.getProspectCountRequest.message = action.payload.message;
      state.prospectCount = action.payload.payload;
    });
    builder.addCase(getProspectCountRequest.rejected, (state, action) => {
      state.getProspectCountRequest.status = RequestStatus.Failed;
      state.getProspectCountRequest.error =
        !action.payload.isHandled && action.payload;
    });
    // Sequence List
    builder.addCase(getSequenceListRequest.pending, (state) => {
      state.getSequenceListRequest.status = RequestStatus.Pending;
      state.getSequenceListRequest.error = null;
    });
    builder.addCase(getSequenceListRequest.fulfilled, (state, action) => {
      state.getSequenceListRequest.status = RequestStatus.Succeeded;
      state.getSequenceListRequest.message = action.payload.message;
      state.sequences = action.payload.payload;
    });
    builder.addCase(getSequenceListRequest.rejected, (state, action) => {
      state.getSequenceListRequest.status = RequestStatus.Failed;
      state.getSequenceListRequest.error =
        !action.payload.isHandled && action.payload;
    });
    // Step List
    builder.addCase(getStepListRequest.pending, (state) => {
      state.getStepListRequest.status = RequestStatus.Pending;
      state.getStepListRequest.error = null;
    });
    builder.addCase(getStepListRequest.fulfilled, (state, action) => {
      state.getStepListRequest.status = RequestStatus.Succeeded;
      state.getStepListRequest.message = action.payload.message;
      state.steps = action.payload.payload;
    });
    builder.addCase(getStepListRequest.rejected, (state, action) => {
      state.getStepListRequest.status = RequestStatus.Failed;
      state.getStepListRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Remove Prospect
    builder.addCase(removeProspectRequest.pending, (state) => {
      state.removeProspectRequest.status = RequestStatus.Pending;
      state.removeProspectRequest.error = null;
    });
    builder.addCase(removeProspectRequest.fulfilled, (state, action) => {
      state.removeProspectRequest.status = RequestStatus.Succeeded;
      state.removeProspectRequest.message = action.payload.message;
      state.steps = action.payload.payload;
    });
    builder.addCase(removeProspectRequest.rejected, (state, action) => {
      state.removeProspectRequest.status = RequestStatus.Failed;
      state.removeProspectRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Unsubscribe Prospect Request
    builder.addCase(unsubscribeProspectRequest.pending, (state) => {
      state.unsubscribeProspectRequest.status = RequestStatus.Pending;
      state.unsubscribeProspectRequest.error = null;
    });
    builder.addCase(unsubscribeProspectRequest.fulfilled, (state, action) => {
      state.unsubscribeProspectRequest.status = RequestStatus.Succeeded;
      state.unsubscribeProspectRequest.message = action.payload.message;
      state.steps = action.payload.payload;
    });
    builder.addCase(unsubscribeProspectRequest.rejected, (state, action) => {
      state.unsubscribeProspectRequest.status = RequestStatus.Failed;
      state.unsubscribeProspectRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Single Contact Details
    builder.addCase(getSingleProspectDetailsRequest.pending, (state) => {
      state.getSingleProspectDetailsRequest.status = RequestStatus.Pending;
      state.getSingleProspectDetailsRequest.error = null;
    });
    builder.addCase(
      getSingleProspectDetailsRequest.fulfilled,
      (state, action) => {
        state.getSingleProspectDetailsRequest.status = RequestStatus.Succeeded;
        state.getSingleProspectDetailsRequest.message = action.payload.message;
        state.singleProspect = action.payload.payload;
      },
    );
    builder.addCase(
      getSingleProspectDetailsRequest.rejected,
      (state, action) => {
        state.getSingleProspectDetailsRequest.status = RequestStatus.Failed;
        state.getSingleProspectDetailsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get Single Contact Sequence Details
    builder.addCase(
      getSingleProspectSequencesDetailsRequest.pending,
      (state) => {
        state.getSingleProspectSequencesDetailsRequest.status =
          RequestStatus.Pending;
        state.getSingleProspectSequencesDetailsRequest.error = null;
      },
    );
    builder.addCase(
      getSingleProspectSequencesDetailsRequest.fulfilled,
      (state, action) => {
        state.getSingleProspectSequencesDetailsRequest.status =
          RequestStatus.Succeeded;
        state.getSingleProspectSequencesDetailsRequest.message =
          action.payload.message;
        state.singleProspectSequenceDetails = action.payload.payload;
      },
    );
    builder.addCase(
      getSingleProspectSequencesDetailsRequest.rejected,
      (state, action) => {
        state.getSingleProspectSequencesDetailsRequest.status =
          RequestStatus.Failed;
        state.getSingleProspectSequencesDetailsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Mark Single Contact Sequence as Replied
    builder.addCase(
      singleProspectSequencesMarkAsRepliedRequest.pending,
      (state) => {
        state.singleProspectSequencesMarkAsRepliedRequest.status =
          RequestStatus.Pending;
        state.singleProspectSequencesMarkAsRepliedRequest.error = null;
      },
    );
    builder.addCase(
      singleProspectSequencesMarkAsRepliedRequest.fulfilled,
      (state, action) => {
        state.singleProspectSequencesMarkAsRepliedRequest.status =
          RequestStatus.Succeeded;
        state.singleProspectSequencesMarkAsRepliedRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      singleProspectSequencesMarkAsRepliedRequest.rejected,
      (state, action) => {
        state.singleProspectSequencesMarkAsRepliedRequest.status =
          RequestStatus.Failed;
        state.singleProspectSequencesMarkAsRepliedRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Mark Single Contact Sequence as Finished
    builder.addCase(
      singleProspectSequencesMarkAsFinishedRequest.pending,
      (state) => {
        state.singleProspectSequencesMarkAsFinishedRequest.status =
          RequestStatus.Pending;
        state.singleProspectSequencesMarkAsFinishedRequest.error = null;
      },
    );
    builder.addCase(
      singleProspectSequencesMarkAsFinishedRequest.fulfilled,
      (state, action) => {
        state.singleProspectSequencesMarkAsFinishedRequest.status =
          RequestStatus.Succeeded;
        state.singleProspectSequencesMarkAsFinishedRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      singleProspectSequencesMarkAsFinishedRequest.rejected,
      (state, action) => {
        state.singleProspectSequencesMarkAsFinishedRequest.status =
          RequestStatus.Failed;
        state.singleProspectSequencesMarkAsFinishedRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Remove From Sequence for single contact
    builder.addCase(
      singleProspectRemoveFromSequencesRequest.pending,
      (state) => {
        state.singleProspectRemoveFromSequencesRequest.status =
          RequestStatus.Pending;
        state.singleProspectRemoveFromSequencesRequest.error = null;
      },
    );
    builder.addCase(
      singleProspectRemoveFromSequencesRequest.fulfilled,
      (state, action) => {
        state.singleProspectRemoveFromSequencesRequest.status =
          RequestStatus.Succeeded;
        state.singleProspectRemoveFromSequencesRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      singleProspectRemoveFromSequencesRequest.rejected,
      (state, action) => {
        state.singleProspectRemoveFromSequencesRequest.status =
          RequestStatus.Failed;
        state.singleProspectRemoveFromSequencesRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Add Contact to step
    builder.addCase(addToStepRequest.pending, (state) => {
      state.addToStepRequest.status = RequestStatus.Pending;
      state.addToStepRequest.error = null;
    });
    builder.addCase(addToStepRequest.fulfilled, (state, action) => {
      state.addToStepRequest.status = RequestStatus.Succeeded;
      state.addToStepRequest.message = action.payload.message;
      state.addToStepRequestResultCount.failed = action.payload.payload.failed;
      state.addToStepRequestResultCount.successful =
        action.payload.payload.successful;
      state.addToStepRequestResultCount.resultData = action.payload.payload;
    });
    builder.addCase(addToStepRequest.rejected, (state, action) => {
      state.addToStepRequest.status = RequestStatus.Failed;
      state.addToStepRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Save contact attributes
    builder.addCase(saveContactAttributesRequest.pending, (state) => {
      state.saveContactAttributesRequest.status = RequestStatus.Pending;
      state.saveContactAttributesRequest.error = null;
    });
    builder.addCase(saveContactAttributesRequest.fulfilled, (state, action) => {
      state.saveContactAttributesRequest.status = RequestStatus.Succeeded;
      state.saveContactAttributesRequest.message = action.payload.message;
      state.singleProspect = action.payload.payload;
    });
    builder.addCase(saveContactAttributesRequest.rejected, (state, action) => {
      state.saveContactAttributesRequest.status = RequestStatus.Failed;
      state.saveContactAttributesRequest.error =
        !action.payload.isHandled && action.payload;
    });
    // saveContactDealValueRequest
    builder.addCase(saveContactDealValueRequest.pending, (state) => {
      state.saveContactDealValueRequest.status = RequestStatus.Pending;
      state.saveContactDealValueRequest.error = null;
    });
    builder.addCase(saveContactDealValueRequest.fulfilled, (state, action) => {
      state.saveContactDealValueRequest.status = RequestStatus.Succeeded;
      state.saveContactDealValueRequest.message = action.payload.message;
      state.singleProspect = action.payload.payload;
    });
    builder.addCase(saveContactDealValueRequest.rejected, (state, action) => {
      state.saveContactDealValueRequest.status = RequestStatus.Failed;
      state.saveContactDealValueRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Fields
    builder.addCase(getFieldsRequest.pending, (state) => {
      state.getFieldsRequest.status = RequestStatus.Pending;
      state.getFieldsRequest.error = null;
    });
    builder.addCase(getFieldsRequest.fulfilled, (state, action) => {
      state.getFieldsRequest.status = RequestStatus.Succeeded;
      state.getFieldsRequest.message = action.payload.message;
      state.fields = action.payload.payload;
    });
    builder.addCase(getFieldsRequest.rejected, (state, action) => {
      state.getFieldsRequest.status = RequestStatus.Failed;
      state.getFieldsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Add prospect manually
    builder.addCase(addProspectManuallyRequest.pending, (state) => {
      state.addProspectManuallyRequest.status = RequestStatus.Pending;
      state.addProspectManuallyRequest.error = null;
    });
    builder.addCase(addProspectManuallyRequest.fulfilled, (state, action) => {
      state.addProspectManuallyRequest.status = RequestStatus.Succeeded;
      state.addProspectResponse = action.payload.payload;
      state.addProspectManuallyRequest.message = action.payload.message;
    });
    builder.addCase(addProspectManuallyRequest.rejected, (state, action) => {
      state.addProspectManuallyRequest.status = RequestStatus.Failed;
      state.addProspectManuallyRequest.error =
        !action.payload?.isHandled && action.payload;
    });

    // Verify Prospect Manually
    builder.addCase(verifyProspectsManuallyRequest.pending, (state) => {
      state.verifyProspectManuallyRequest.status = RequestStatus.Pending;
      state.verifyProspectManuallyRequest.error = null;
    });
    builder.addCase(
      verifyProspectsManuallyRequest.fulfilled,
      (state, action) => {
        state.verifyProspectManuallyRequest.status = RequestStatus.Succeeded;
        state.verifyProspectManuallyRequest.message = action.payload.message;
      },
    );
    builder.addCase(
      verifyProspectsManuallyRequest.rejected,
      (state, action) => {
        state.verifyProspectManuallyRequest.status = RequestStatus.Failed;
        state.verifyProspectManuallyRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Tags
    builder.addCase(getTagsRequest.pending, (state) => {
      state.getTagsRequest.status = RequestStatus.Pending;
      state.getTagsRequest.error = null;
    });
    builder.addCase(getTagsRequest.fulfilled, (state, action) => {
      state.getTagsRequest.status = RequestStatus.Succeeded;
      state.getTagsRequest.message = action.payload.message;
      state.tags = action.payload.payload;
    });
    builder.addCase(getTagsRequest.rejected, (state, action) => {
      state.getTagsRequest.status = RequestStatus.Failed;
      state.getTagsRequest.error = !action.payload.isHandled && action.payload;
    });

    // Add Tags to Prospects
    builder.addCase(addTagsToProspectsRequest.pending, (state) => {
      state.addTagsToProspectsRequest.status = RequestStatus.Pending;
      state.addTagsToProspectsRequest.error = null;
    });
    builder.addCase(addTagsToProspectsRequest.fulfilled, (state, action) => {
      state.addTagsToProspectsRequest.status = RequestStatus.Succeeded;
      state.addTagsToProspectsRequest.message = action.payload.message;
    });
    builder.addCase(addTagsToProspectsRequest.rejected, (state, action) => {
      state.addTagsToProspectsRequest.status = RequestStatus.Failed;
      state.addTagsToProspectsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Remove Tags to Prospects
    builder.addCase(removeTagsFromProspectsRequest.pending, (state) => {
      state.removeTagsFromProspectsRequest.status = RequestStatus.Pending;
      state.removeTagsFromProspectsRequest.error = null;
    });
    builder.addCase(
      removeTagsFromProspectsRequest.fulfilled,
      (state, action) => {
        state.removeTagsFromProspectsRequest.status = RequestStatus.Succeeded;
        state.removeTagsFromProspectsRequest.message = action.payload.message;
      },
    );
    builder.addCase(
      removeTagsFromProspectsRequest.rejected,
      (state, action) => {
        state.removeTagsFromProspectsRequest.status = RequestStatus.Failed;
        state.removeTagsFromProspectsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Verify Bulk Prospects
    builder.addCase(verifyBulkProspectsRequest.pending, (state) => {
      state.verifyBulkProspectsRequest.status = RequestStatus.Pending;
      state.verifyBulkProspectsRequest.error = null;
    });
    builder.addCase(verifyBulkProspectsRequest.fulfilled, (state, action) => {
      state.verifyBulkProspectsRequest.status = RequestStatus.Succeeded;
      state.verifyBulkProspectsRequest.message = action.payload.payload.message;
    });
    builder.addCase(verifyBulkProspectsRequest.rejected, (state, action) => {
      state.verifyBulkProspectsRequest.status = RequestStatus.Failed;
      state.verifyBulkProspectsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Delete Bulk Prospects
    builder.addCase(deleteBulkProspectsRequest.pending, (state) => {
      state.deleteBulkProspectsRequest.status = RequestStatus.Pending;
      state.deleteBulkProspectsRequest.error = null;
    });
    builder.addCase(deleteBulkProspectsRequest.fulfilled, (state, action) => {
      state.deleteBulkProspectsRequest.status = RequestStatus.Succeeded;
      state.deleteBulkProspectsRequest.message = action.payload.payload.message;
    });
    builder.addCase(deleteBulkProspectsRequest.rejected, (state, action) => {
      state.deleteBulkProspectsRequest.status = RequestStatus.Failed;
      state.deleteBulkProspectsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Unsubscribe Bulk Prospects
    builder.addCase(unsubscribeBulkProspectRequest.pending, (state) => {
      state.unsubscribeBulkProspectRequest.status = RequestStatus.Pending;
      state.unsubscribeBulkProspectRequest.error = null;
    });
    builder.addCase(
      unsubscribeBulkProspectRequest.fulfilled,
      (state, action) => {
        state.unsubscribeBulkProspectRequest.status = RequestStatus.Succeeded;
        state.unsubscribeBulkProspectRequest.message =
          action.payload.payload.message;
        state.steps = action.payload.payload;
      },
    );
    builder.addCase(
      unsubscribeBulkProspectRequest.rejected,
      (state, action) => {
        state.unsubscribeBulkProspectRequest.status = RequestStatus.Failed;
        state.unsubscribeBulkProspectRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Add Bulk Prospects To Step
    builder.addCase(addBulkProspectsToStepRequest.pending, (state) => {
      state.addBulkProspectsToStepRequest.status = RequestStatus.Pending;
      state.addBulkProspectsToStepRequest.error = null;
    });
    builder.addCase(
      addBulkProspectsToStepRequest.fulfilled,
      (state, action) => {
        state.addBulkProspectsToStepRequest.status = RequestStatus.Succeeded;
        state.addBulkProspectsToStepRequest.message =
          action.payload.payload.message;
        state.addToStepRequestResultCount.failed =
          action.payload.payload.failed;
        state.addToStepRequestResultCount.successful =
          action.payload.payload.successful;
        state.addToStepRequestResultCount.resultData = action.payload.payload;
      },
    );
    builder.addCase(addBulkProspectsToStepRequest.rejected, (state, action) => {
      state.addBulkProspectsToStepRequest.status = RequestStatus.Failed;
      state.addBulkProspectsToStepRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Add Tags To Bulk Prospects
    builder.addCase(addTagsToBulkProspectsRequest.pending, (state) => {
      state.addTagsToBulkProspectsRequest.status = RequestStatus.Pending;
      state.addTagsToBulkProspectsRequest.error = null;
    });
    builder.addCase(
      addTagsToBulkProspectsRequest.fulfilled,
      (state, action) => {
        state.addTagsToBulkProspectsRequest.status = RequestStatus.Succeeded;
        state.addTagsToBulkProspectsRequest.message =
          action.payload.payload.message;
      },
    );
    builder.addCase(addTagsToBulkProspectsRequest.rejected, (state, action) => {
      state.addTagsToBulkProspectsRequest.status = RequestStatus.Failed;
      state.addTagsToBulkProspectsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Remove Tags From Bulk Prospects
    builder.addCase(removeTagsFromBulkProspectsRequest.pending, (state) => {
      state.removeTagsFromBulkProspectsRequest.status = RequestStatus.Pending;
      state.removeTagsFromBulkProspectsRequest.error = null;
    });
    builder.addCase(
      removeTagsFromBulkProspectsRequest.fulfilled,
      (state, action) => {
        state.removeTagsFromBulkProspectsRequest.status =
          RequestStatus.Succeeded;
        state.removeTagsFromBulkProspectsRequest.message =
          action.payload.payload.message;
      },
    );
    builder.addCase(
      removeTagsFromBulkProspectsRequest.rejected,
      (state, action) => {
        state.removeTagsFromBulkProspectsRequest.status = RequestStatus.Failed;
        state.removeTagsFromBulkProspectsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Prospect primary email change
    builder.addCase(changePrimaryEmailInProspectRequest.pending, (state) => {
      state.changePrimaryEmailInProspectRequest.status = RequestStatus.Pending;
      state.changePrimaryEmailInProspectRequest.error = null;
    });
    builder.addCase(
      changePrimaryEmailInProspectRequest.fulfilled,
      (state, action) => {
        state.changePrimaryEmailInProspectRequest.status =
          RequestStatus.Succeeded;
        state.changePrimaryEmailInProspectRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      changePrimaryEmailInProspectRequest.rejected,
      (state, action) => {
        state.changePrimaryEmailInProspectRequest.status = RequestStatus.Failed;
        state.changePrimaryEmailInProspectRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Delete secondary email from prospect
    builder.addCase(deleteSecondaryEmailRequest.pending, (state) => {
      state.deleteSecondaryEmailRequest.status = RequestStatus.Pending;
      state.deleteSecondaryEmailRequest.error = null;
    });
    builder.addCase(deleteSecondaryEmailRequest.fulfilled, (state, action) => {
      state.deleteSecondaryEmailRequest.status = RequestStatus.Succeeded;
      state.deleteSecondaryEmailRequest.message = action.payload.message;
    });
    builder.addCase(deleteSecondaryEmailRequest.rejected, (state, action) => {
      state.deleteSecondaryEmailRequest.status = RequestStatus.Failed;
      state.deleteSecondaryEmailRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Delete secondary Phone from prospect
    builder.addCase(deleteSecondaryPhoneRequest.pending, (state) => {
      state.deleteSecondaryPhoneRequest.status = RequestStatus.Pending;
      state.deleteSecondaryPhoneRequest.error = null;
    });
    builder.addCase(deleteSecondaryPhoneRequest.fulfilled, (state, action) => {
      state.deleteSecondaryPhoneRequest.status = RequestStatus.Succeeded;
      state.deleteSecondaryPhoneRequest.message = action.payload.message;
    });
    builder.addCase(deleteSecondaryPhoneRequest.rejected, (state, action) => {
      state.deleteSecondaryPhoneRequest.status = RequestStatus.Failed;
      state.deleteSecondaryPhoneRequest.error =
        !action.payload.isHandled && action.payload;
    });
  },
});
export const {
  resetGetProspectListRequest,
  setFilters,
  resetGetProspectFiltersFieldsListRequest,

  changeProspectPagination,
  resetProspect,
  saveSearch,
  resetSequenceAndStepData,
  selectAllContacts,
  clearSelection,
  checkAll,
  checkSingle,
  resetStep,
  resetSelectedContacts,
  setProspectDetails,
  resetAddToProspectForm,
  setProspectsFilters,
  resetProspectsFilters,
  resetTags,
  resetRemoveProspectRequest,
  resetBulkRequests,
  resetUnsubscribeRequestState,
  resetSaveContactAttributesRequestStatus,
  resetProspectDeleteEmailRequestStatus,
  resetProspectDeletePhoneRequestStatus,
  resetProspectPrimaryEmailChangeRequestStatus,
} = prospectSlice.actions;

export default prospectSlice.reducer;
