import * as Sentry from '@sentry/react';
import { LocationDescriptor } from 'history';
import { getToken, removeToken, setToken } from './token';
import { resetState } from './reset-state';
import { redirectWithoutRefresh } from './redirect';
import loader from '../loader';
// eslint-disable-next-line import/no-cycle
import api from '../../api';
import toaster from '../toaster';
import { AnalyticsEvents } from '../enums/analytics';
import { clearTestEmailAddressFromLocalStorage } from '../../components/sequence/shared/modals/email-modal/helpers';
import { removeProspectsFiltersFromLocalStore } from '../../components/prospect/helpers/prospect-filters-resolver';
import { removeEmailInboxFiltersFromLocalStore } from '../../components/unified-inbox/helpers/utils/email-inbox-filters-local-storage-handler';
import { removeCobaltSessionToken } from '../../components/settings/components/integrations/utils/helper';
import { removeSequenceFilterFromLocalStorage } from '../../components/sequence/components/sequence-list/helper';
import {
  removeSequenceReportsFilters,
  removeTeamPerformanceFilters,
} from '../../components/reports/helpers/helper';
import { removeTasksFiltersFromLocalStore } from '../../components/tasks/components/tasks-content/utils/helper';
import { removeCustomScript } from './initialize-custom-script';
import { removeProspectTableColumnsFromLocalStorage } from '../../components/prospect/components/prospect-table/utils/helper';

export type LoginOptions = {
  redirect?: boolean;
  token: string;
};

export type LocationDescriptorOptions = LocationDescriptor & {
  pathname?: string;
};

export type LogoutOptions = {
  redirect?: boolean;
  pathName?: string;
  fallbackTo?: LocationDescriptorOptions;
  query?: string;
  isBackToMainSHDomain?: boolean;
};

type FallbackUrlState = {
  from: LocationDescriptorOptions;
};

const deleteTokenAndRedirect = ({
  redirect = true,
  pathName = '/login',
  fallbackTo,
  query,
  isBackToMainSHDomain,
}: LogoutOptions = {}) => {
  removeToken();
  resetState();
  // cleanup
  loader.hide();

  const signupRoute = '/signup';
  const redirectURL =
    fallbackTo && fallbackTo.pathname === signupRoute ? signupRoute : pathName;

  if (isBackToMainSHDomain) {
    window.open(process.env.REACT_APP_BASE_URL, '_self');

    return;
  }

  if (redirect) {
    redirectWithoutRefresh<FallbackUrlState>({
      pathname: redirectURL,
      state: { from: fallbackTo },
      search: query,
    });
  }
};

const deleteTestEmailFromLocalStorage = () =>
  clearTestEmailAddressFromLocalStorage();

export class AuthHelper {
  static get isAuthenticated(): boolean {
    const token = new URLSearchParams(window.location.search).get('token');
    if (token) {
      setToken(token);
      return true;
    }
    const isTokenPresent = !!getToken();
    if (isTokenPresent) {
      return isTokenPresent;
    }

    return false;
  }

  static login({ redirect = true, token }: LoginOptions) {
    setToken(token);

    if (redirect) {
      redirectWithoutRefresh('/');
    }
  }

  static async logout({
    redirect = true,
    pathName,
    fallbackTo,
    query = '',
    isBackToMainSHDomain = false,
  }: LogoutOptions = {}) {
    if (getToken()) {
      api
        .get('/auth/logout', {
          headers: { authorization: `Bearer ${getToken()}` },
        })
        .then(() => {
          toaster.success('Successfully logged out!');

          // Analytics Track this event
          window.analytics?.track(AnalyticsEvents.SignedOut);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.log('Session expired');
        });
    }

    // Sentry, set user to null on logout
    Sentry.setUser(null);

    removeCustomScript();

    deleteTokenAndRedirect({
      redirect,
      pathName,
      fallbackTo,
      query,
      isBackToMainSHDomain,
    });

    // Delete test_email_address
    deleteTestEmailFromLocalStorage();

    // Removing filters from local store
    removeProspectsFiltersFromLocalStore();
    removeEmailInboxFiltersFromLocalStore();
    removeSequenceFilterFromLocalStorage();

    // Delete cobalt token from local store
    removeCobaltSessionToken();

    // Remove Prospect Table Columns
    removeProspectTableColumnsFromLocalStorage();

    // Remove task filters from local storage
    removeTasksFiltersFromLocalStore();

    removeTeamPerformanceFilters();

    // Remove Sequence Reports filters
    removeSequenceReportsFilters();
  }
}
